import React, { ForwardedRef, ReactNode } from 'react';

interface SectionProps {
  children: ReactNode;
  className?: string;
}

export const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  ({ children, className }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref} className={`py-20 ${className}`}>
        {children}
      </div>
    );
  }
);
