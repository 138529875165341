import df from 'assets/images/logos/darkforest.png';
import ls from 'assets/images/logos/lootsurvivor.png';
import primodium from 'assets/images/logos/primodium.png';
import piratnation from 'assets/images/logos/pirate-nation.png';
import skystrife from 'assets/images/logos/skystrife.png';
import stepn from 'assets/images/logos/stepn.png';

export interface Game {
  name: string;
  desc: string;
  color: string;
  image: string;
  link: string;
}

export const WE_PLAY_DATA: Game[] = [
  {
    name: 'Dark Forest',
    desc: 'decentralized rts game, built with zk-snarks',
    image: df,
    link: 'https://twitter.com/darkforest_eth',
    color: '#00FF08'
  },
  {
    name: 'Primodium',
    desc: 'autonomous world rts built with mud',
    image: primodium,
    link: 'https://twitter.com/primodiumgame',
    color: '#3396FF'
  },
  {
    name: 'Loot Survivor',
    desc: 'play2die adventure',
    image: ls,
    link: 'https://twitter.com/LootRealms',
    color: '#16F53E'
  },
  {
    name: 'Pirate Nation',
    desc: 'A new type of game, filled with high seas adventure, treasure, fun',
    image: piratnation,
    link: 'https://twitter.com/PirateNation',
    color: '#E28F3F'
  },
  {
    name: 'Sky Strife',
    desc: 'The fully onchain real-time strategy game',
    image: skystrife,
    link: 'https://twitter.com/skystrifeHQ',
    color: '#F6BE52'
  },
  {
    name: 'Stepn',
    desc: 'web3 lifestyle app',
    image: stepn,
    link: 'https://twitter.com/Stepnofficial',
    color: '#56FF97'
  }
];
