import { createContext, useState } from 'react';

import { SectionContextState } from 'models';

const initialState: SectionContextState = {
  sectionId: '',
  setSectionId: (): string => ''
};

export const PageNavContext = createContext<SectionContextState>(initialState);

export const PageNavContextProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [sectionId, setSectionId] = useState<string>('');

  return (
    <PageNavContext.Provider
      value={{
        sectionId,
        setSectionId
      }}
    >
      {children}
    </PageNavContext.Provider>
  );
};
