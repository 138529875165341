import bg from 'assets/images/background/background.webp';
import flower2 from 'assets/images/background/flower2.webp';
import midgroundFar from 'assets/images/background/midground-far.webp';
import flower1 from 'assets/images/background/flower1.webp';
import smokeMid from 'assets/images/background/smoke-mid.webp';
import midgroundClose from 'assets/images/background/midground-close.webp';
import smokeClose from 'assets/images/background/smoke-close.webp';
import foreground from 'assets/images/background/foreground.webp';

export function Nature({ className }: { className?: string }) {
  return (
    <div className={`absolute left-0 top-0 h-full w-full overflow-hidden ${className}`}>
      <div
        className='absolute h-full w-full bg-cover bg-center opacity-90 blur-sm filter'
        style={{ backgroundImage: `url(${bg})`, backgroundAttachment: 'fixed' }}
      ></div>
      <div
        className='animate-flower2 absolute h-full w-full bg-cover bg-center opacity-90 blur-sm filter'
        style={{ backgroundImage: `url(${flower2})`, transformOrigin: '23% 68%' }}
      ></div>
      <div
        className='animate-flower1 absolute h-full w-full bg-cover bg-center opacity-90'
        style={{ backgroundImage: `url(${flower1})`, transformOrigin: '85% 68%' }}
      ></div>
      <div
        className='blur-px absolute h-full w-full bg-cover bg-center opacity-90 filter'
        style={{ backgroundImage: `url(${midgroundFar})` }}
      ></div>
      <div
        className='animate-smoke absolute h-full w-full bg-contain opacity-90 blur-sm filter'
        style={{ backgroundImage: `url(${smokeMid})`, transform: 'translateX(-100%)', animationDelay: '10s' }}
      ></div>
      <div
        className='absolute h-full w-full bg-cover bg-center opacity-90 blur-sm filter'
        style={{ backgroundImage: `url(${midgroundClose})` }}
      ></div>
      <div
        className='animate-smoke absolute h-full w-full bg-cover bg-center opacity-45'
        style={{ backgroundImage: `url(${smokeClose})`, transform: 'translateX(-80%)', animationDuration: '60s' }}
      ></div>
      <div
        className='absolute h-full w-full bg-cover bg-center opacity-90'
        style={{ backgroundImage: `url(${foreground})` }}
      ></div>
      <div className='absolute inset-0 bg-black opacity-20'></div>
    </div>
  );
}
