import React, { useState, useEffect, useRef } from 'react';
import { AsciiGenerator } from 'utils/ascii';

interface AsciiImageProps {
  imageUrl: string;
  size: number;
}

type GeneratorState = AsciiGenerator | null;

const AsciiImage: React.FC<AsciiImageProps> = ({ imageUrl, size }) => {
  const [generator, setGenerator] = useState<GeneratorState>(null);
  const animationFrame = useRef<number | null>(null);
  const animationStarted = useRef(false);

  // Internal animation settings
  const initialBrightness = -1;
  const initialContrast = 0;
  const targetBrightness = 0.7;
  const targetContrast = 0.2;
  const speed = 0.03; // Adjust the speed here

  const brightness = useRef(initialBrightness);
  const contrast = useRef(initialContrast);

  useEffect(() => {
    const newGenerator = new AsciiGenerator();
    setGenerator(newGenerator);

    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
    };
  }, []);

  useEffect(() => {
    if (generator) {
      generator.settings.url = imageUrl;
      generator.settings.size = size;
      generator.settings.charSamples = 1;
      generator.loadFromUrl();
    }
  }, [imageUrl, size, generator]);

  useEffect(() => {
    if (generator && !animationStarted.current) {
      animationStarted.current = true;

      const animate = () => {
        // Update brightness and contrast
        brightness.current += speed * (targetBrightness - brightness.current);
        contrast.current += speed * (targetContrast - contrast.current);

        // Stop animation if close to target values
        if (
          Math.abs(brightness.current - targetBrightness) < 0.01 &&
          Math.abs(contrast.current - targetContrast) < 0.01
        ) {
          brightness.current = targetBrightness;
          contrast.current = targetContrast;
          generator.settings.brightness = brightness.current;
          generator.settings.contrast = contrast.current;
          generator.normalizeValueMap();
          generator.generate();
          return; // End the animation
        }

        // Apply settings
        generator.settings.brightness = brightness.current;
        generator.settings.contrast = contrast.current;
        generator.normalizeValueMap();
        generator.generate();

        // Request next frame
        animationFrame.current = requestAnimationFrame(animate);
      };

      // Start animation
      animationFrame.current = requestAnimationFrame(animate);

      return () => {
        if (animationFrame.current) {
          cancelAnimationFrame(animationFrame.current);
        }
      };
    }
  }, [generator]);

  return (
    <div>
      <div
        id='ascii'
        className='grid text-[9px]'
        style={{
          gridTemplateColumns: 'repeat(var(--width), 1fr)',
          width: 'calc(var(--width) * 5.41px)',
          height: 'calc(var(--height) * 10px)'
        }}
      ></div>
      <div id='debug-image'></div>
      <div id='debug-chars'></div>
    </div>
  );
};

export default AsciiImage;
