import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export interface SocialProps {
  icon: IconName;
  link: string;
  iconPrefix?: IconPrefix;
  className?: string;
}

export const SOCIAL_DATA: SocialProps[] = [
  {
    icon: 'twitter',
    link: 'https://twitter.com/orden_gg'
  },
  {
    icon: 'discord',
    link: 'https://discord.gg/orden'
  },
  {
    icon: 'github',
    link: 'https://github.com/orden-gg'
  }
];
