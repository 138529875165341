import { ReactNode, useContext } from 'react';

import { SectionContextState } from 'models';
import { PageNavContext } from '../../contexts';

interface PageNavLinkProps {
  children: ReactNode;
  id: string;
}

export function PageNavLink({ children, id }: PageNavLinkProps) {
  const { sectionId, setSectionId } = useContext<SectionContextState>(PageNavContext);

  const handleClick = () => {
    document.getElementById(`${id}-section`)?.scrollIntoView({ behavior: 'smooth' });
    setSectionId(id);
  };

  const linkActive = id === sectionId;

  return (
    <a
      className={`nav-item group flex cursor-pointer items-center justify-end uppercase ${linkActive && 'isActive'}`}
      onClick={handleClick}
    >
      <span
        className={`transition-all duration-500 ${
          linkActive ? 'text-primary opacity-100' : 'text-default opacity-0 group-hover:opacity-100'
        }`}
      >
        {children}
      </span>
      <span
        className={`triangle ml-2 h-2.5 w-2.5 rotate-45 transition-all duration-500 ${
          linkActive ? 'bg-primary' : 'bg-secondary group-hover:bg-white'
        }`}
      ></span>
    </a>
  );
}
