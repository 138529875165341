import { useContext, useEffect, useRef, useState } from 'react';

import { PageNavContext } from 'contexts';
import { SectionContextState } from 'models';
import { CommonUtils } from 'utils';
import { MAIN_SECTION } from 'pages/main/main.enum';

export const useNav = (navLinkName: MAIN_SECTION) => {
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);
  const { setSectionId } = useContext<SectionContextState>(PageNavContext);
  const ref = useRef(null);

  const observer = new IntersectionObserver(
    ([entry]) => {
      setIsOnScreen(entry.isIntersecting);
    },
    {
      rootMargin: '100px 0px -100px 0px',
      threshold: 0.5
    }
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isOnScreen) {
      setSectionId(CommonUtils.stringToKey(navLinkName));
    }
  }, [isOnScreen]);

  return ref;
};
