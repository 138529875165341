import { Container, Section, Title } from 'components';
import { WE_PLAY_DATA } from 'data';
import { useNav } from 'hooks/useNav';
import { MAIN_SECTION } from 'pages/main/main.enum';

export function WePlaySection() {
  const ref = useNav(MAIN_SECTION.WePlay);

  return (
    <Section ref={ref} className='we-play h-unset bg-neutral-700'>
      <Title className='mb-12'>Games</Title>

      <Container>
        <div className='grid-cols-2 justify-end gap-4 sm:grid md:grid-cols-3'>
          {WE_PLAY_DATA.map((game, index) => (
            <a
              className='card card-compact mb-4 grid bg-base-100 shadow-xl before:z-10 before:col-start-1 before:row-start-1 before:rounded-lg before:bg-slate-900 before:opacity-50 hover:bottom-0.5 before:hover:opacity-60 sm:mb-0'
              href={game.link}
              target='_blank'
              rel='noopener noreferrer'
              key={`game-${index}`}
            >
              <figure className='col-start-1 row-start-1 rounded-lg'>
                <img className='h-full object-cover' src={game.image} alt={game.name} />
              </figure>
              <div
                className='card-body relative z-20 col-start-1 row-start-1 rounded-lg drop-shadow'
                style={{ border: `2px solid ${game.color}` }}
              >
                <h2 className='card-title' style={{ color: game.color }}>
                  {game.name}
                </h2>
                <p>{game.desc}</p>
              </div>
            </a>
          ))}

        </div>
      </Container>
    </Section>
  );
}
