import { Container, Section, Title } from 'components';
import { useNav } from 'hooks/useNav';
import { MAIN_SECTION } from 'pages/main/main.enum';

export function AboutUsSection() {
  const ref = useNav(MAIN_SECTION.AboutUs);

  return (
    <Section ref={ref} className='about-us h-unset bg-neutral-900'>
      <Title className='mb-12'>Lore</Title>
      <Container>
        <div className='grid gap-8 md:grid-cols-2'>
          <div className='space-y-4'>
            <h3 className='text-2xl font-bold text-primary'>Inception</h3>
            <p className='text-lg text-gray-300'>
              orden, along with dfdao, was one of the first clans operating within the virtual worlds of the future.
              Created in 2021, orden played and learned the laws of the autonomous world, starting with a Dark Forest
              experiment where we won the majority of the official rounds. Since our initial success, we have
              participated in various gaming DAO experiments, building tools and expansions.
            </p>
          </div>
          <div className='space-y-4'>
            <h3 className='text-2xl font-bold text-primary'>Vision</h3>
            <p className='text-lg text-gray-300'>
              We believe that network states and autonomous worlds are developing similar structures in terms of
              population and economy, and that eventually this structure will rival the largest economies, and most
              populous countries. In this context, our role is to create a network corporation — a coordinated network
              of people, agents, incentives, and other tools — to succeed in and evolve these worlds.
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
}
