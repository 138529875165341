import { Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { Main } from 'pages';
import { PageNavContextProvider } from 'contexts';

import 'react-medium-image-zoom/dist/styles.css';

library.add(fas, far, fab); // ? Initialize FontAwesome

function App() {
  return (
    <div className='scroll-smooth selection:bg-secondary/25 selection:text-secondary'>
      <PageNavContextProvider>
        <Routes>
          <Route path='' element={<Main />} />
        </Routes>
      </PageNavContextProvider>
    </div>
  );
}

export default App;
