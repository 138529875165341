import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocialProps } from 'data';
import { AnchorHTMLAttributes } from 'react';

interface SocialPropsComponent extends SocialProps, AnchorHTMLAttributes<HTMLAnchorElement> {}

export function Social({ icon, link, iconPrefix, className }: SocialPropsComponent) {
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <kbd className={`kbd transition-all hover:border-primary hover:bg-neutral-900 hover:text-primary ${className}`}>
        <FontAwesomeIcon className='icon' width={18} icon={[iconPrefix ? iconPrefix : 'fab', icon]} />
      </kbd>
    </a>
  );
}
