import { CommonUtils } from 'utils';
import { PageNavLink } from './pageNavLink.component';

export function PageNav({ navList }: { navList: string[] }) {
  return (
    <div className='fixed bottom-1/2 right-3 z-50 translate-y-1/2'>
      {navList.map((name: string) => {
        const linkId = CommonUtils.stringToKey(name.toLowerCase());

        return (
          <PageNavLink key={name} id={linkId}>
            {name}
          </PageNavLink>
        );
      })}
    </div>
  );
}
