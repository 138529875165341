import { EmArgument } from 'models/em.model';

export class CommonUtils {
  public static em(...args: EmArgument[]) {
    const fs = Number(args.pop()) as number;

    return args
      .map((value: number | string | number[]) => {
        if (typeof value === 'string') {
          return value;
        } else {
          return `${Number(value) / Number(fs)}em`;
        }
      })
      .join(' ');
  }

  public static stringToKey(string: string, divider = '-'): string {
    return string.replace(/’| /g, divider).replace(/ /g, divider).toLowerCase();
  }

  public static removeWhitespace(string: string): string {
    return string.replace(/\s+/g, '');
  }
}
