import { PageNav } from 'components/pageNav/pageNav.component';
import { PageNavContext } from 'contexts';
import { CommonUtils } from 'utils';
import { MAIN_SECTION } from './main.enum';

import { AboutUsSection, HeroSection, InterludeSection, TeamSection, WeBuildSection, WePlaySection } from './sections';
import { memo, useContext, useMemo } from 'react';

const navList = Object.values(MAIN_SECTION);

const Section = memo(({ children, sectionName }: { children: JSX.Element; sectionName: MAIN_SECTION }): JSX.Element => {
  const { sectionId } = useContext(PageNavContext);

  const isActive = useMemo(
    () =>
      CommonUtils.stringToKey(MAIN_SECTION[CommonUtils.removeWhitespace(sectionName)]) === sectionId ? 'active' : '',
    [sectionId, sectionName]
  );

  return (
    <section
      id={`${CommonUtils.stringToKey(MAIN_SECTION[CommonUtils.removeWhitespace(sectionName)])}-section`}
      className={isActive}
    >
      {children}
    </section>
  );
});

export function Main() {
  return (
    <div>
      <PageNav navList={navList} />
      <Section sectionName={MAIN_SECTION.Hero}>
        <HeroSection />
      </Section>
      <Section sectionName={MAIN_SECTION.AboutUs}>
        <AboutUsSection />
      </Section>
      <Section sectionName={MAIN_SECTION.WeBuild}>
        <WeBuildSection />
      </Section>
      <Section sectionName={MAIN_SECTION.WePlay}>
        <WePlaySection />
      </Section>
      <Section sectionName={MAIN_SECTION.Team}>
        <TeamSection />
      </Section>
      <Section sectionName={MAIN_SECTION.Interlude}>
        <InterludeSection />
      </Section>
      {/* <Section sectionName={MAIN_SECTION.Lore}>
        <LoreSection />
      </Section> */}
    </div>
  );
}
