import { ISourceOptions } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { useEffect, useState } from 'react';

export function HeroBackground() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = {
    background: {
      color: '#101010'
    },
    fullScreen: false,
    particles: {
      number: {
        value: 100
      },
      move: {
        direction: 'none',
        enable: true,
        outModes: {
          default: 'out'
        },
        random: true,
        speed: 0.5,
        straight: false
      },
      opacity: {
        animation: {
          enable: true,
          speed: 1,
          sync: false
        },
        value: { min: 0, max: 1 }
      },
      size: {
        value: { min: 1, max: 3 }
      }
    }
  };

  return init ? <Particles id='tsparticles' className='absolute inset-0' options={options} /> : <span></span>;
}
