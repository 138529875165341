import primodium from 'assets/images/logos/primodium.png';
import tarochi from 'assets/images/logos/tarochi.png';
import portal from 'assets/images/logos/portal.webp';
import { Game } from './we-play.data';
import { MAIN_SECTION } from 'pages/main/main.enum';

interface WeBuildGame extends Game {
  soon?: boolean;
  scrollTo?: MAIN_SECTION;
  features: { [key: string]: string };
}

export const WE_BUILD_DATA: WeBuildGame[] = [
  {
    name: 'Portal',
    desc: 'aw metagame',
    image: portal,
    link: '#',
    color: '#fff200',
    scrollTo: MAIN_SECTION.Interlude,
    soon: false,
    features: {
      metagame: '',
      clans: '',
      events: '',
      reputation: '',
    }
  },
  {
    name: 'Outland',
    desc: 'autonomous world rts build with mud',
    image: primodium,
    link: 'https://primodium.orden.gg/',
    color: '#3396FF',
    features: {
      wiki: 'general information about game units, buildings, objectives, etc...',
      leaderboard: 'guilds/players leaderboard with additional data',
      market: 'live market prices with swap simulation',
      map: 'overall universe overview'
    }
  },
  {
    name: 'Tarochi client',
    desc: 'autonomous world RPG',
    image: tarochi,
    link: 'https://tarochi.orden.gg/',
    color: '#DD594C',
    features: {
      wiki: 'the stats and skills of all the Tarochi monsters',
      monitor: 'keeps up to date with the currently spawning mons across the map'
    }
  }
];
