import { Container, Section, Title } from 'components';
import { useNav } from 'hooks/useNav';
import { MAIN_SECTION } from 'pages/main/main.enum';
import { Nature } from '../components/nature.component';

export function InterludeSection() {
  const ref = useNav(MAIN_SECTION.Interlude);

  return (
    <Section ref={ref} className=' relative flex min-h-screen flex-col bg-zinc-800'>
      <Nature />
      <Container className='relative z-10 flex grow items-center justify-center'>
        <div>
          <Title className='relative z-20 mb-12 text-white drop-shadow-lg'>Portal</Title>
          <div className='mx-auto max-w-3xl space-y-6 rounded-md bg-black/25 p-8 text-center backdrop-blur-sm backdrop-filter'>

            <p className='text-lg text-white'>
              You're about to enter the network corporation metagame. Our corporation's objective is to maintain a delicate balance of power, expand our network influence, and capture epic artifacts.
            </p>
            <p className='text-lg font-medium text-white'>
              First, you'll pass through the academy, where you'll learn basic skills and make connections. Be cautious — different clans control different zones in this metagame. While they may be allies, they're still competing for glory.
            </p>

            <p className='text-xl font-semibold text-primary drop-shadow-md'>
              <a href="#">Step into the portal and join the fight!</a>
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
}
